<template>
    <div :class="{ 'prices': true, 'no-grid': netPrice}">
        <div v-if="product.purchasePrice">
            <div :class="{'prices__price-container': (viewType && !netPrice), 'cy-price': true}">
                <span class="prices__price-type">
                    <template v-if="isKernProduct && !hasFromSuffix">
                        {{ $t('Product.kern_price') }}
                    </template>
                    <template v-else-if="isKernProduct && hasFromSuffix">
                      {{ $t('Product.kern_price') }} {{ $t('Product.from') }}
                    </template>
                    <template v-else>
                        {{ hasNetPrice ? $t('Cart.net_ek').toUpperCase() : $t('Product.ek_price')}}
                        {{ netPrice && hasFromSuffix ? $t('Product.from') : '' }}
                    </template>
                </span>
                <span
                    class="prices__price"
                    :class="{
                         'prices__kern-background': isIntersportKernPriceType
                         && isLabelActive.kern,
                         'prices__fk-background': isIntersportKernPriceType
                         && isLabelActive.fk,
                         'prices__tile': viewType === 'tile',
                      }"
                >
                    {{ useMoneyFormat(product.purchasePrice) }}
                </span>
            </div>
        </div>

        <div v-if="product.sellPrice">
            <div :class="{'prices__price-container': (viewType && !netPrice), 'cy-price-upe': true}">
                <span class="prices__price-type">
                    {{ product.sellPriceOriginal ? $t('Product.upa_price') : $t('Product.upe_price') }}
                    {{ netPrice && hasFromSuffix ? $t('Product.from') : '' }}
                </span>
                <span
                    class="prices__price"
                    :class="{'prices__tile': viewType === 'tile',}"
                >
                    {{ useMoneyFormat(product.sellPrice) }}
                </span>
            </div>
        </div>

        <div v-if="product.purchasePriceOriginal">
            <div :class="{'prices__price-container': (viewType && !netPrice), 'prices__price-container-original': viewType}">
                <span
                    class="prices__price-type"
                    :class="{'prices__price-original': viewType !== 'tile',}"
                >
                    {{ $t('Product.ek_price') }} {{ netPrice && hasFromSuffix ? $t('Product.from') : '' }}
                </span>
                <span
                    class="prices__price prices__price-original line-through"
                    :class="{'prices__tile': viewType === 'tile',}"
                >
                    {{ useMoneyFormat(product.purchasePriceOriginal) }}
                </span>
            </div>
        </div>

        <div v-if="product.sellPriceOriginal">
            <div
                :class="{
                    'prices__price-container': (viewType && !netPrice),
                    'prices__price-container-original': viewType,
                    'cy-price-upe-stroke': true
                }"
            >
                <span
                    class="prices__price-type"
                    :class="{'prices__price-original': viewType !== 'tile',}"
                >
                    {{ $t('Product.upe_price') }} {{ netPrice && hasFromSuffix ? $t('Product.from') : '' }}
                </span>
                <span
                    class="prices__price prices__price-original line-through"
                    :class="{'prices__tile': viewType === 'tile',}"
                >
                    {{ useMoneyFormat(product.sellPriceOriginal) }}
                </span>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import type { ProductPrice } from '~/composables/types/api/searchDiscover/catalogSearch';
import type { ProductPrice as SuggestionsProductPrice } from '~/composables/types/api/searchDiscover/catalogSearchSuggestions';
import type { NetProductPrices } from '~/composables/types/api/cartConditions/netPrices';
import useMoneyFormat from '~/composables/utils/useMoneyFormat';

const props = withDefaults(defineProps<{
    product: ProductPrice | SuggestionsProductPrice | NetProductPrices,
    viewType?: string | null,
    hasFromSuffix?: boolean,
    hasNetPrice?: boolean,
}>(), {
    viewType: null,
    hasFromSuffix: false,
    hasNetPrice: false,
});

const intersportKernFkPriceType = 11;
const isLabelActive = ref({
    kern: props.product?.labels?.includes('KERN'),
    fk: props.product?.labels?.includes('FK'),
});

const netPrice = computed(() => (props.viewType === 'netPrice'));

const isIntersportKernPriceType = computed(() => props.product.purchasePriceIntersportType === intersportKernFkPriceType);

const isKernProduct = computed(() => isIntersportKernPriceType.value &&
    (isLabelActive.value.kern || isLabelActive.value.fk));

watch(() => props.product.labels, () => {
    isLabelActive.value = {
        kern: props.product?.labels?.includes('KERN'),
        fk: props.product?.labels?.includes('FK'),
    };
});
</script>

<style lang="scss" scoped>
.prices {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    white-space: nowrap;

    div {
        margin-right: sp(xxs);
    }

    &__price-container {
        margin-bottom: sp(xs);
        margin-top: sp(xxs);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        @include helper-color(text-secondary);
    }

    &__price-container-original {
        margin-bottom: 0;
    }

    &__price-type {
        @include helper-font-size(smaller);
        @include helper-font-weight(medium);
    }

    &__price {
        margin-left: sp(xxs);

        @include helper-font-size(big);
        @include helper-font-weight(bold);
        @include helper-color(text-title);
    }

    &__tile {
        margin-left: 0;
    }

    &__price-original {
        @include helper-color(alert-danger);
    }

    &__kern-background {
        padding-left: sp(xxs);
        padding-right: sp(xxs);

        @include helper-color-bg(label-kern);
        @include helper-color(white);
        @include helper-border-radius(rem(7));
    }

    &__fk-background {
        padding-left: sp(xxs);
        padding-right: sp(xxs);

        @include helper-color-bg(label-fk);
        @include helper-border-radius(rem(7));
    }

    .line-through {
        text-decoration: line-through;

        @include helper-font-weight(medium);
        @include helper-font-size(default);
    }

    .hidden {
        visibility: hidden;
    }
}

.no-grid {
    display: inline;
}

</style>
